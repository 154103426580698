const ADD_NEW_CARD = 'add-card';
const PAYPAL_VENMO = 'paypal-venmo-payment';
const PAYPAL = 'paypal';
const VENMO = 'venmo';

export const SELECTED_CARD_TYPES = {
    ADD_NEW_CARD,
    PAYPAL,
    PAYPAL_VENMO,
    VENMO
};
