import {updateCartInput as IUpdateCartInput, updateCartItemInput as IUpdateCartItemInput} from 'autogen/globalTypes';

import {
    bulkUpdateCartItemsMutation,
    deleteCartItemMutation,
    updateCartGroceryOptionsMutation,
    updateCartItemMutation,
    updateCartMutation,
    updateCartSubstitutionPreferenceMutation
} from '../cart-mutations';
import {updateCartCacheAfterCartItemDelete} from '../mutation-helpers/cart-mutation-helpers';
import {graphqlClient} from '../../graphql-client';
import {getActiveCart} from '../../queries/cart-queries';

export const deleteCartItemAction = async (cartItemId, customerId, updateCache = true) => {
    await graphqlClient().mutate({
        errorPolicy: 'all',
        mutation: deleteCartItemMutation,
        optimisticResponse: {
            __typename: 'Mutation',
            deleteCartItem: cartItemId
        },
        update: () => (updateCache ? updateCartCacheAfterCartItemDelete(cartItemId) : null),
        variables: {
            cartItemId: Number(cartItemId),
            customerId
        }
    });
};

export const updateCartItemAction = (
    customerId,
    {cartItemId, tax, ...cartItemInput}: IUpdateCartItemInput,
    useOptimisticResponse = true,
    includePromotions = false
) => {
    const optimisticResponse = {
        __typename: 'Mutation',
        updateCartItem: {
            __typename: 'cartItem',
            cartItemId: Number(cartItemId),
            tax,
            ...cartItemInput
        }
    };
    const optionsForClpe = {
        optimisticResponse,
        refetchQueries: [
            {
                query: getActiveCart,
                variables: {
                    customerId,
                    includePromotions
                }
            }
        ]
    };

    const mutationOptions = useOptimisticResponse ? {optimisticResponse} : {...optionsForClpe};

    return graphqlClient().mutate({
        awaitRefetchQueries: true,
        errorPolicy: 'all',
        mutation: updateCartItemMutation,
        ...mutationOptions,
        variables: {
            cartItemInput: {
                cartItemId: Number(cartItemId),
                ...cartItemInput
            },
            customerId
        }
    });
};

export const bulkUpdateCartItemsAction = (customerId, bulkUpdateCartItemsInput: IUpdateCartItemInput[]) => {
    graphqlClient().mutate({
        errorPolicy: 'all',
        mutation: bulkUpdateCartItemsMutation,
        optimisticResponse: {
            __typename: 'Mutation',
            bulkUpdateCartItems: bulkUpdateCartItemsInput.map((cartItemInput) => ({
                __typename: 'cartItem',
                ...cartItemInput
            }))
        },
        variables: {
            bulkUpdateCartItemsInput,
            customerId
        }
    });
};

export const updateCartSubPreferenceAction = ({...updateCartInput}: IUpdateCartInput, useOptimisticResponse = true) => {
    const optimisticResponseBody = useOptimisticResponse
        ? {
              optimisticResponse: {
                  __typename: 'Mutation',
                  updateCart: {
                      __typename: 'cart',
                      ...updateCartInput
                  }
              }
          }
        : {};

    return graphqlClient().mutate({
        errorPolicy: 'all',
        mutation: updateCartSubstitutionPreferenceMutation,
        ...optimisticResponseBody,
        variables: {
            updateCartInput
        }
    });
};

export const resetCartFulfillmentLocationAction = (cartId) =>
    graphqlClient().mutate({
        mutation: updateCartMutation,
        variables: {
            updateCartInput: {
                cartId,
                deliveryAddressId: null,
                fulfillmentExpiration: null,
                fulfillmentId: null,
                fulfillmentLocationId: null,
                fulfillmentType: 'NOT_SELECTED',
                fulfillmentWindowEnd: null,
                fulfillmentWindowStart: null,
                pickupLocationId: null,
                storeId: null
            }
        }
    });

export const updateCartGroceryOptionsAction = (updateCartInput: IUpdateCartInput) =>
    graphqlClient().mutate({
        mutation: updateCartGroceryOptionsMutation,
        variables: {
            updateCartInput
        }
    });
