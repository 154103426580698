import {gql} from '@apollo/client';

import {productImageFragment} from './product-images-queries';
import {wicItemFragment} from './product-queries';

export const cartItemFragment = gql`
    ${productImageFragment}
    ${wicItemFragment}

    fragment ICartItem on cartItem {
        cartItemId
        cartId
        note
        productId
        quantity
        quantityType
        tax
        substitutionPreference
        userSelectedSubstitutionId
        wicAppliedQty @include(if: $wicEnabled)
        retailItem {
            item {
                itemId
                unitAverageWeight
                WicItems(locationIds: $locationIds) @include(if: $wicEnabled) {
                    ...IWicItemFragment
                }
                nutrition @include(if: $foodHealthScoreEnabled) {
                    score
                }
                upcHyVee
            }
            soldByUnitOfMeasure {
                code
            }
            tagPrice
            tagPriceQuantity
            ecommerceTagPrice
            ecommerceTagPriceQuantity
            memberTagPrice
            memberTagPriceQuantity
            fuelSaverAmount
            sellingRules
        }
        storeProduct {
            storeProductId
            onSale
            onFuelSaver
            isWeighted
            isActive
            isAlcohol
            fuelSaver
            price
            priceMultiple
            taxRate
            returnAmount
            departmentId
            department {
                departmentId
            }
            departmentGroup {
                departmentGroupId
                name
            }
            product {
                name
                productId
                size
                averageWeight
                upc
                isNotEligibleForDelivery
                productImages(where: {isPrimary: true, size: "THUMBNAIL"}) {
                    ...ProductImageFragment
                }
            }
            storeProductDescriptions {
                type
                description
            }
        }
        userSelectedSubstitution @include(if: $substitutionDataEnabled) {
            userSelectedSubstitutionId
            userSelectedProductId
            productId
            rank
        }
        clpeCartItemPromotions @include(if: $includePromotions) {
            promotionNumber
            startDate
            endDate
            description
            rewardType
            rewardValue
            threshold {
                type
                value
            }
        }
        wicCartItem @include(if: $wicEnabled) {
            wicLineSubtotalWithPromotions
            wicLineDeals
        }
        wicPromotions @include(if: $wicEnabled) {
            freeItemQuantity
            perUnitRewardAmount
        }
    }
`;

export const cartItemsFragment = gql`
    fragment ICartItems on cart {
        cartItems {
            ...ICartItem
        }
    }
    ${cartItemFragment}
`;

export const productCardCartItemFragment = gql`
    fragment IProductCardCartItem on cartItem {
        cartItemId
        cartId
        quantity
        quantityType
        productId
        storeProduct {
            storeProductId
            product {
                productId
                averageWeight
            }
        }
    }
`;

export const productCardCartItemsFragment = gql`
    fragment IProductCardCartItems on cart {
        cartItems {
            ...IProductCardCartItem
        }
    }
    ${productCardCartItemFragment}
`;

export const cartItemUserSubstitutionMutateFragment = gql`
    fragment ICartItemUserSubstitutionUpdateFragment on cartItem {
        cartItemId
        userSelectedSubstitution {
            userSelectedSubstitutionId
            userSelectedProductId
            productId
            rank
        }
        userSelectedSubstitutionId
    }
`;

export const cartItemQuantityMutateFragment = gql`
    fragment ICartItemQuantityUpdate on cartItem {
        cartItemId
        quantity
    }
`;

export const cartItemDeleteCartLookupFragment = gql`
    fragment ICartItemDeleteCartLookupFragment on cartItem {
        cartItemId
        cartId
    }
`;
