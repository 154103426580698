import {INTERNAL_SERVER_ERROR} from 'http-status';
import {FormikProps} from 'formik';

import {PLACE_ORDER_FAILURE, PLACE_ORDER_REQUEST, PLACE_ORDER_SUCCESS} from '../../action-types';
import {postMessageToIframeParent} from '../../utils/iframe-helpers';

export interface IPlaceOrderValues {
    canBuyAgeRestrictedItems: boolean; // PPD: age restricted items
    checkoutMode: number; // from enums/checkout-types
    customerCardId: unknown;
    deliveryProvider: unknown;
    endUserLicenseAgreementToAccept: unknown;
    formikProps: FormikProps<unknown>;
    fulfillmentType: unknown;
    includesGiftCard: unknown;
    isSnapEBT: boolean;
    leaveUnattended: boolean; // PPD: contactless delivery
    madeToOrderCartId: unknown;
    orderOrigin: unknown;
    phoneNumber: unknown;
    riskifiedBeaconUuid: string;
    storeId: unknown;
    taxExemptName: unknown;
    taxExemptNumber: unknown;
    userHasAcceptedTermsAndConditions: boolean;
    paypal?: unknown;
    wicDiscountAmount?: unknown;
}

export const placeOrder =
    (values: IPlaceOrderValues, onSuccessCb?: (_data: any) => void, onFailureCb?: (_error: any) => void) =>
    (dispatch, getState) => {
        const {
            canBuyAgeRestrictedItems,
            checkoutMode,
            customerCardId,
            deliveryProvider,
            endUserLicenseAgreementToAccept,
            formikProps,
            fulfillmentType,
            includesGiftCard,
            isSnapEBT,
            leaveUnattended,
            madeToOrderCartId,
            orderOrigin,
            phoneNumber,
            riskifiedBeaconUuid,
            storeId,
            taxExemptName,
            taxExemptNumber,
            userHasAcceptedTermsAndConditions,
            paypal,
            wicDiscountAmount
        } = values;

        const {
            customer: {customerId},
            groceryDeliveryTip: {deliveryTip}
        } = getState();

        const getErrorMessage = (response) =>
            response.statusCode === INTERNAL_SERVER_ERROR
                ? 'An error has occurred. Please try again.'
                : response.message;

        return dispatch({
            fetch: {
                onFailure: (dispatchFromMiddleware, getStateFromMiddleware, error) => {
                    error.response.json().then((response) => {
                        dispatchFromMiddleware({
                            orderInProgress: false,
                            placeOrderErrorStatus: getErrorMessage(response),
                            type: PLACE_ORDER_FAILURE
                        });
                    });

                    if (onFailureCb) {
                        onFailureCb(error);
                    }

                    formikProps?.setSubmitting?.(false);
                },
                onSuccess: (dispatchFromMiddleware, getStateFromMiddleware, data) => {
                    dispatchFromMiddleware({
                        type: PLACE_ORDER_SUCCESS,
                        ...data
                    });
                    postMessageToIframeParent({
                        body: data,
                        message: 'order-creation-successful'
                    });
                    onSuccessCb?.(data);
                },
                options: {
                    body: JSON.stringify({
                        canBuyAgeRestrictedItems,
                        checkoutMode,
                        customerCardId,
                        deliveryProvider,
                        deliveryTip,
                        endUserLicenseAgreementToAccept,
                        fulfillmentType,
                        includesGiftCard,
                        isSnapEBT,
                        leaveUnattended,
                        mtoCartId: madeToOrderCartId,
                        orderOrigin,
                        paypal,
                        phoneNumber,
                        riskifiedBeaconUuid,
                        storeId,
                        taxExemptName,
                        taxExemptNumber,
                        userHasAcceptedTermsAndConditions,
                        wicDiscountAmount
                    }),
                    method: 'POST'
                },
                url: `/aisles-online/api/customer/${customerId}/orders`
            },
            type: PLACE_ORDER_REQUEST
        });
    };
