/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BagPreferenceType {
  PAPER = "PAPER",
  PLASTIC = "PLASTIC",
}

/**
 * Derived from: aisles-online-api
 * 
 * `BROWSING`
 * `HERO`
 * `SECONDARY_TOP`
 * `SECONDARY_MIDDLE`
 * `SECONDARY_BOTTOM`
 * `SEARCH`
 */
export enum BannerType {
  BROWSING = "BROWSING",
  HERO = "HERO",
  SEARCH = "SEARCH",
  SECONDARY_BOTTOM = "SECONDARY_BOTTOM",
  SECONDARY_MIDDLE = "SECONDARY_MIDDLE",
  SECONDARY_TOP = "SECONDARY_TOP",
}

export enum BuyAgainProductsSortDirection {
  FREQUENT = "FREQUENT",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  RECENT = "RECENT",
  RELEVANT = "RELEVANT",
}

export enum ChargeStatusType {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  FAILED = "FAILED",
  INVALID = "INVALID",
  LOCKED = "LOCKED",
  PENDING = "PENDING",
}

export enum CustomerOrderType {
  ALCOHOL_ONLY = "ALCOHOL_ONLY",
  GROCERY = "GROCERY",
  MADE_TO_ORDER = "MADE_TO_ORDER",
  PHARMACY = "PHARMACY",
}

export enum DeliveryAddressLocationType {
  Business = "Business",
  Residence = "Residence",
}

export enum EcommerceStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum FeedbackRating {
  AGREE = "AGREE",
  DISAGREE = "DISAGREE",
}

export enum FulfillmentType {
  DELIVERY = "DELIVERY",
  NOT_SELECTED = "NOT_SELECTED",
  PICKUP = "PICKUP",
  PICKUP_LOCKER = "PICKUP_LOCKER",
  PICKUP_POINT = "PICKUP_POINT",
}

export enum ItemSource {
  HYVEE = "HYVEE",
}

export enum ItemStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

/**
 * Derived from: aisles-online-api
 * One of: [TERMS_AND_CONDITIONS, MOBILE_TERMS_AND_CONDITIONS, MEMBERSHIP_TERMS_AND_CONDITIONS]
 */
export enum LicenseAgreementType {
  MEMBERSHIP_TERMS_AND_CONDITIONS = "MEMBERSHIP_TERMS_AND_CONDITIONS",
  MOBILE_TERMS_AND_CONDITIONS = "MOBILE_TERMS_AND_CONDITIONS",
  TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
}

/**
 * Ecommerce status of a location
 */
export enum LocationEcommerceStatus {
  ACTIVE = "ACTIVE",
  DELIVERY_LOCATION = "DELIVERY_LOCATION",
  DELIVERY_ONLY = "DELIVERY_ONLY",
  INACTIVE = "INACTIVE",
  PICKUP_LOCATION = "PICKUP_LOCATION",
  PICKUP_ONLY = "PICKUP_ONLY",
}

export enum MembershipType {
  HYVEE_PLUS = "HYVEE_PLUS",
}

export enum MtoFulfillmentType {
  COURIER = "COURIER",
  DELIVERY = "DELIVERY",
  NONE = "NONE",
  PICKUP = "PICKUP",
}

export enum OrderAddressType {
  BILLING = "BILLING",
  SHIPPING = "SHIPPING",
}

export enum OrderAdjustmentType {
  OUT_OF_STOCK = "OUT_OF_STOCK",
  QUANTITY = "QUANTITY",
  SALE = "SALE",
  WEIGHT = "WEIGHT",
}

export enum PickupInstructionType {
  LOCKER_BARCODE = "LOCKER_BARCODE",
  LOCKER_INSTRUCTION = "LOCKER_INSTRUCTION",
  LOCKER_PIN = "LOCKER_PIN",
  STEP_ONE = "STEP_ONE",
  STEP_THREE = "STEP_THREE",
  STEP_TWO = "STEP_TWO",
}

/**
 * Predicted products surfaced by ProdX personalized by customer UUID
 */
export enum PredictionTags {
  DIFFERENT = "DIFFERENT",
  DISCREET = "DISCREET",
  MANY = "MANY",
  ONE = "ONE",
  SAME = "SAME",
  SEASONAL = "SEASONAL",
}

export enum PriorityType {
  EXPRESS = "EXPRESS",
  STANDARD = "STANDARD",
}

export enum SearchSortDirection {
  NAME_A_To_Z = "NAME_A_To_Z",
  NAME_Z_To_A = "NAME_Z_To_A",
  PRICE_ASCENDING = "PRICE_ASCENDING",
  PRICE_DESCENDING = "PRICE_DESCENDING",
  RELEVANCE = "RELEVANCE",
}

export enum SellingRuleType {
  ALCOHOL_RESTRICTED = "ALCOHOL_RESTRICTED",
  SNAP_ELIGIBLE = "SNAP_ELIGIBLE",
  TOBACCO_RESTRICTED = "TOBACCO_RESTRICTED",
}

export enum ShelfProductActionType {
  CART_ADD = "CART_ADD",
  CART_QUANTITY_DECREMENT = "CART_QUANTITY_DECREMENT",
  CART_QUANTITY_INCREMENT = "CART_QUANTITY_INCREMENT",
  CART_REMOVE = "CART_REMOVE",
  COMPARE_ADD = "COMPARE_ADD",
  COMPARE_REMOVE = "COMPARE_REMOVE",
  LIST_ADD = "LIST_ADD",
  LIST_REMOVE = "LIST_REMOVE",
  OTHER = "OTHER",
  PRODUCT_VIEW = "PRODUCT_VIEW",
  RIGHT_CLICK = "RIGHT_CLICK",
}

export enum SortDirection {
  A_TO_Z = "A_TO_Z",
  PRICE_ASCENDING = "PRICE_ASCENDING",
  PRICE_DESCENDING = "PRICE_DESCENDING",
  RELEVANCE = "RELEVANCE",
  Z_TO_A = "Z_TO_A",
}

/**
 *  Preference type for subs otion
 */
export enum SubstitutionPreference {
  BEST_MATCH = "BEST_MATCH",
  LET_ME_CHOOSE = "LET_ME_CHOOSE",
  NO_SUBSTITUTION = "NO_SUBSTITUTION",
}

export enum TransactionActionType {
  CAPTURE = "CAPTURE",
  PREAUTH = "PREAUTH",
  VOID = "VOID",
}

export enum TransactionCardType {
  CUSTOMER_CARD = "CUSTOMER_CARD",
  GIFT_CARD = "GIFT_CARD",
  PAYPAL = "PAYPAL",
  WIC_CARD = "WIC_CARD",
}

export enum UomName {
  EACH = "EACH",
  POUND = "POUND",
}

export enum listInvitationStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
}

export enum listItemType {
  Deal = "Deal",
  MobileDeal = "MobileDeal",
}

export enum promoFilterEnum {
  BOGO = "BOGO",
  TEN_FOR_TEN = "TEN_FOR_TEN",
}

/**
 * the type for input parameters when searching by category
 */
export interface AislesOnlineCategorySearchInput {
  categoryId: string;
  storeId: number;
  searchFilterInput?: SearchFilterInput[] | null;
  sortDirection?: SearchSortDirection | null;
  pageInfoInput?: PageInfoInput | null;
  aisleId?: string | null;
}

/**
 * the type for input parameters when searching by term
 */
export interface AislesOnlineSearchInput {
  searchTerm?: string | null;
  storeId: number;
  spellCorrect?: boolean | null;
  numberOfSponsoredProductsPerPage?: number | null;
  searchFilterInput?: SearchFilterInput[] | null;
  sortDirection?: SearchSortDirection | null;
  pageInfoInput?: PageInfoInput | null;
  aisleId?: string | null;
  includeWicFilter?: boolean | null;
}

export interface BasketContinuityCartInput {
  fulfillmentType: string;
  storeId?: number | null;
  pickupLocationId?: number | null;
  fulfillmentId?: number | null;
  fulfillmentLocationId?: number | null;
  fulfillmentExpiration?: string | null;
  fulfillmentWindowStart?: string | null;
  fulfillmentWindowEnd?: string | null;
  deliveryAddressId?: number | null;
}

export interface BuyAgainProductsInput {
  customerUuid: string;
  storeId?: number | null;
  searchTerm?: string | null;
  sortDirection?: BuyAgainProductsSortDirection | null;
  groupBy?: string | null;
  aisleId?: string | null;
}

export interface CategoriesGroupsInput {
  storeId: number;
  categoryId: string;
  customerUuid?: string | null;
  aisleId?: string | null;
}

export interface ComplementsProductsInput {
  aisleId?: string | null;
  customerId?: string | null;
  storeId: number;
  productId: number;
}

/**
 * Used to provide filters for the coupons resolver
 */
export interface CouponsFilter {
  all?: boolean | null;
  categories?: string | null;
  index?: number | null;
  sortOrder?: string | null;
  sortBy?: string | null;
  size?: number | null;
  upc?: (string | null)[] | null;
}

export interface CreateSubstitutionFeedback {
  orderId: string;
  productId: number;
  substitutionProductId?: number | null;
  rating: FeedbackRating;
  reason?: string | null;
  description?: string | null;
}

export interface CreateUnshoppedOrderItemInput {
  orderItemUuid: string;
  resourceId: string;
  expectedQuantity: number;
  requestedUnitOfMeasure?: UomName | null;
  soldByUnitOfMeasure?: UomName | null;
}

export interface CustomerOrderRelationshipInput {
  orderId: string;
  customerOrderType: CustomerOrderType;
}

export interface OrderTaxDetailByOrderNumberInput {
  orderNumber: number;
  taxExemptNumber: string;
  taxExemptName: string;
}

export interface OrderV3Input {
  customerUuid?: string | null;
  date?: string | null;
  fulfillmentDate?: string | null;
  fulfillmentLocationId?: number | null;
  fulfillmentWindowEnd?: string | null;
  fulfillmentWindowStart?: string | null;
  isActive?: boolean | null;
  isPaid?: boolean | null;
  orderIds?: string[] | null;
  orderId?: string | null;
  orderNumbers?: number[] | null;
  orderNumber?: number | null;
  orderStatusIds?: number[] | null;
  source?: string | null;
  storeIds?: string[] | null;
  types?: string[] | null;
}

export interface PageInfoInput {
  pageSize?: number | null;
  page?: number | null;
}

export interface ProdxMetadata {
  customerId?: string | null;
  catalogId?: string | null;
  visitorId?: string | null;
  ua?: string | null;
  ip?: string | null;
}

export interface RecordWicDirectLoginInput {
  authorizationCode: string;
  verifier: string;
  redirectUri: string;
  stateAgencyId: string;
}

export interface SearchFilterInput {
  id: string;
  name?: string | null;
  searchFilterOptions: SearchFilterOptionInput[];
}

export interface SearchFilterOptionInput {
  id: string;
  name?: string | null;
}

export interface SetWicAppliedQuantitiesForCartItemsInput {
  cartId: string;
  items: WicCartItemInput[];
}

export interface SlugFilter {
  _key?: StringFilter | null;
  _type?: StringFilter | null;
  current?: StringFilter | null;
  source?: StringFilter | null;
}

export interface StringFilter {
  eq?: string | null;
  neq?: string | null;
  matches?: string | null;
  in?: string[] | null;
  nin?: string[] | null;
  is_defined?: boolean | null;
}

/**
 * the type for input parameters when publishing a shelf product action
 */
export interface SubmitShelfProductActionInput {
  id?: string | null;
  shelfId?: string | null;
  shelfProductId?: string | null;
  aisleId?: string | null;
  shelfProductActionTypeId?: ShelfProductActionType | null;
  responseId?: string | null;
  responseProductId?: string | null;
  actionTypeId?: ShelfProductActionType | null;
  metadata?: ProdxMetadata | null;
}

export interface UnleashProperties {
  storeCode?: string | null;
  mobileBuildNumber?: number | null;
  sessionId?: string | null;
  userId?: string | null;
  fulfillmentWindowStartHour?: string | null;
  email?: string | null;
}

export interface UpdateSubstitutionFeedback {
  id: string;
  orderId: string;
  reason?: string | null;
  rating?: FeedbackRating | null;
  description?: string | null;
}

export interface VariationsGroupsInput {
  storeId: number;
  productId: number;
}

export interface WicCartItemInput {
  id: string;
  wicAppliedQuantity: number;
  cvbWicAppliedBenefitsQty?: number | null;
}

export interface confirmListInvitationInput {
  token: string;
  status: listInvitationStatus;
}

export interface createCartInput {
  customerId: number;
  bagPreferenceType?: BagPreferenceType | null;
  fulfillmentType?: FulfillmentType | null;
  storeId?: number | null;
  pickupLocationId?: number | null;
  fulfillmentId?: number | null;
  fulfillmentLocationId?: number | null;
  fulfillmentExpiration?: string | null;
  fulfillmentWindowStart?: string | null;
  fulfillmentWindowEnd?: string | null;
  deliveryAddressId?: number | null;
  vehicleColor?: string | null;
  vehicleType?: string | null;
  instructions?: string | null;
  requestsPickupLocationToBeWheelchairAccessible?: boolean | null;
  substitutionPreference?: SubstitutionPreference | null;
  smsNotificationPhoneNumber?: string | null;
}

/**
 *  Type for creating a new cart item 
 */
export interface createCartItemInput {
  productId: number;
  storeProductId: number;
  quantity: number;
  quantityType: string;
  retailItemId?: string | null;
  substitutionPreference: SubstitutionPreference;
  tax?: number | null;
  userSelectedSubstitutionId?: number | null;
  note?: string | null;
}

export interface createCartPromotionInput {
  isApplied: boolean;
  aislesOnlinePromotionId: number;
}

export interface createCartTransactionActionInput {
  cartTransactionId: string;
  actionType: TransactionActionType;
  amount: number;
  created: string;
}

export interface createCartTransactionInput {
  cartId: number;
  chargeId: string;
  cardType: TransactionCardType;
}

export interface createListInput {
  listId: string;
  name: string;
  description?: string | null;
  created: string;
  lastModified: string;
  isPrimary: boolean;
  isRemoved: boolean;
}

export interface createListItemInput {
  listItemId: string;
  listId: string;
  created: string;
  addedBy?: string | null;
  dealId?: number | null;
  itemId?: string | null;
  type?: listItemType | null;
  lastModified: string;
  productId?: number | null;
  upc?: string | null;
  text: string;
  quantity?: number | null;
  isWeighted?: boolean | null;
  isChecked: boolean;
  isRemoved: boolean;
  reversedOrderIndex: number;
}

export interface createMtoTransactionActionInput {
  mtoTransactionId: string;
  actionType: string;
  amount: number;
  created: string;
}

export interface createMtoTransactionCartItemInput {
  mtoTransactionId: string;
  cartItemId: number;
}

export interface createMtoTransactionInput {
  chargeId: string;
  cardType: TransactionCardType;
  mtoCartId: string;
}

/**
 * input type for creating a user selcted substitution
 */
export interface createUserSelectedSubstitutionInput {
  productId: number;
  userSelectedProductId: number;
  rank: number;
  equivalentQty?: number | null;
}

export interface listInvitationsInput {
  invitationId?: string | null;
  listId?: string | null;
  invitationEmail?: string | null;
  status?: listInvitationStatus | null;
  asInvitee?: boolean | null;
  excludeExpired?: boolean | null;
}

export interface multipleListItemInput {
  listItemId: string;
  created: string;
  addedBy?: string | null;
  dealId?: number | null;
  itemId?: string | null;
  type?: listItemType | null;
  lastModified: string;
  productId?: number | null;
  upc?: string | null;
  text?: string | null;
  quantity?: number | null;
  isWeighted?: boolean | null;
  isChecked: boolean;
  isRemoved: boolean;
  reversedOrderIndex: number;
}

export interface removeListCollaboratorInput {
  listId: string;
  customerUuid: string;
}

export interface storeDealsInput {
  departmentIds?: (number | null)[] | null;
  storeAdIds?: (number | null)[] | null;
  hasFuelSaver?: boolean | null;
  savingsId?: number | null;
  hasBoughtBefore?: boolean | null;
  fuelSaverCardNumber?: string | null;
  activeOnly?: boolean | null;
  useTotalCount?: boolean | null;
  promoFilter?: (promoFilterEnum | null)[] | null;
  searchTerm?: string | null;
  sortDirection?: SortDirection | null;
}

export interface submitListInvitationInput {
  listId: string;
  email: string;
}

export interface updateCartInput {
  bagPreferenceType?: BagPreferenceType | null;
  canBuyAgeRestrictedItems?: boolean | null;
  cartId: string;
  deliveryAddressId?: number | null;
  fulfillmentExpiration?: string | null;
  fulfillmentId?: number | null;
  fulfillmentLocationId?: number | null;
  fulfillmentType?: FulfillmentType | null;
  fulfillmentWindowEnd?: string | null;
  fulfillmentWindowStart?: string | null;
  instructions?: string | null;
  leaveUnattended?: boolean | null;
  pickupLocationId?: number | null;
  requestsPickupLocationToBeWheelchairAccessible?: boolean | null;
  smsNotificationPhoneNumber?: string | null;
  storeId?: number | null;
  substitutionPreference?: SubstitutionPreference | null;
  vehicleColor?: string | null;
  vehicleType?: string | null;
}

/**
 *  Type for updating an existing cart item 
 */
export interface updateCartItemInput {
  cartItemId: number;
  productId?: number | null;
  storeProductId?: number | null;
  quantity?: number | null;
  quantityType?: string | null;
  retailItemId?: string | null;
  tagPrice?: number | null;
  tagPriceQuantity?: number | null;
  substitutionPreference?: SubstitutionPreference | null;
  tax?: number | null;
  note?: string | null;
  userSelectedSubstitutionId?: number | null;
}

export interface updateCartPromotionInput {
  cartPromotionId: number;
  isApplied?: boolean | null;
}

export interface updateListInput {
  listId: string;
  name?: string | null;
  description?: string | null;
  created?: string | null;
  lastModified?: string | null;
  isPrimary?: boolean | null;
  isRemoved?: boolean | null;
}

export interface updateListItemInput {
  listItemId: string;
  listId?: string | null;
  created?: string | null;
  dealId?: number | null;
  itemId?: string | null;
  type?: listItemType | null;
  lastModified?: string | null;
  productId?: number | null;
  upc?: string | null;
  text?: string | null;
  quantity?: number | null;
  isWeighted?: boolean | null;
  isChecked?: boolean | null;
  isRemoved?: boolean | null;
  reversedOrderIndex?: number | null;
}

export interface updateMadeToOrderCartItemInput {
  cartItemId: number;
  cartId?: string | null;
  productId?: number | null;
  quantity?: number | null;
  storeId?: number | null;
  fulfillmentType?: string | null;
  fulfillmentWindowStart?: string | null;
  fulfillmentWindowEnd?: string | null;
  tax?: number | null;
  taxExemptAmount?: number | null;
  parentCartItemId?: number | null;
}

/**
 * input type for updating an existing user selected substitution
 */
export interface updateUserSelectedSubstitutionInput {
  userSelectedSubstitutionId: string;
  userSelectedProductId?: number | null;
  rank?: number | null;
  equivalentQty?: number | null;
}

export interface wherePickupLocationInput {
  pickupLocationId?: string | null;
  fulfillmentLocationId?: number | null;
  name?: string | null;
  phoneNumber?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  fulfillmentStoreId?: number | null;
  pickupStoreId?: number | null;
  passcode?: string | null;
  fulfillmentType?: string | null;
  isActive?: boolean | null;
  pickupLocationHasLocker?: boolean | null;
}

export interface whereProductImageInput {
  size?: string | null;
  isPrimary?: boolean | null;
  viewType?: (string | null)[] | null;
}

export interface whereStoreProductInput {
  productId?: number | null;
  price?: number | null;
  priceMultiple?: number | null;
  fuelSaver?: number | null;
  onFuelSaver?: boolean | null;
  onSale?: boolean | null;
  departmentGroupId?: number | null;
  departmentId?: number | null;
  mixAndMatchId?: number | null;
  categoryId?: number | null;
  subcategoryId?: number | null;
  storeId?: number | null;
  isAlcohol?: boolean | null;
  isWeighted?: boolean | null;
  isActive?: boolean | null;
  insertDate?: string | null;
  taxRate?: number | null;
  returnAmount?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
