import {gql} from '@apollo/client';

import {productImageFragment} from './product-images-queries';

const retailItemsFragment = gql`
    fragment IRetailItemFragment on RetailItem {
        retailItemId
        basePrice
        basePriceQuantity
        soldByUnitOfMeasure {
            code
            name
        }
        tagPrice
        tagPriceQuantity
        ecommerceTagPrice
        ecommerceTagPriceQuantity
        memberTagPrice
        memberTagPriceQuantity
        sellingRules
    }
`;

export const storeProductFragment = gql`
    fragment IStoreProductFragment on storeProduct {
        storeProductId
        productId
        onFuelSaver
        onSale
        fuelSaver
        price
        basePrice
        priceMultiple
        isWeighted
        isActive
        isAlcohol
        insertDate
        departmentId
        taxRate
        storeProductDescriptions {
            type
            description
        }
    }
`;

export const wicItemFragment = gql`
    fragment IWicItemFragment on WicItem {
        isCvb
        isBroadbandAllowed
        wicExchangeRate
        wicItemId
        wicSubcategory {
            categoryCode
            categoryDescription
            subcategoryCode
            subcategoryDescription
            unitOfMeasure
            isBroadbandSubcategory
        }
        upcHyVee
    }
`;

export const wicCartItemFragment = gql`
    fragment IWicCartItemFragment on cartItem {
        cartItemId
        wicCartItem @include(if: $wicEnabled) {
            id
            wicLineSubtotalWithPromotions
        }
    }
`;

export const itemFragment = gql`
    ${wicItemFragment}

    fragment IItemFragment on Item {
        itemId
        description
        ecommerceStatus
        source
        images {
            imageId
            url
            isPrimaryImage
        }
        source
        unitAverageWeight
        WicItems(locationIds: $locationIds) @include(if: $wicEnabled) {
            ...IWicItemFragment
        }
    }
`;

export const productFragment = gql`
    fragment IProductFragment on product {
        productId
        name
        size
        averageWeight
        upc
        brandName
        isNotEligibleForDelivery
        productImages(where: {viewType: ["default", "full_bleed"]}) {
            ...ProductImageFragment
        }
        couponProductV4(targeted: $targeted) {
            upc
            couponsV4 {
                couponId
                brand
                offerState
                valueText
            }
        }
        productLockers @include(if: $pickupLocationHasLocker) {
            productLockerId
            pickupLocationId
            isLockerEligible
        }
        storeProduct(storeId: $storeId, isActive: true) {
            ...IStoreProductFragment
        }
        item {
            nutrition @include(if: $foodHealthScoreEnabled) {
                score
            }
            ...IItemFragment
            retailItems(locationIds: $locationIds) @include(if: $retailItemEnabled) {
                ...IRetailItemFragment
            }
        }
    }

    ${itemFragment}
    ${retailItemsFragment}
    ${storeProductFragment}
    ${productImageFragment}
`;

export const getProductThumbnailsByProductIds = gql`
    ${productImageFragment}

    query getProductThumbnailsByProductIds($productIds: [Int!], $size: String!) {
        products(whereIds: $productIds) {
            products {
                productId
                name
                productImages(where: {viewType: ["default", "full_bleed"], size: $size}) {
                    ...ProductImageFragment
                }
                size
            }
        }
    }
`;

export const fullProductFragment = gql`
    ${productImageFragment}

    fragment IFullProductFragment on product {
        name
        productId
        size
        averageWeight
        upc
        productImages(where: {size: "MEDIUM"}) {
            ...ProductImageFragment
        }
        productLockers @include(if: $pickupLocationHasLocker) {
            productLockerId
            pickupLocationId
            isLockerEligible
        }
    }
`;

const prodxProductFragment = gql`
    ${productFragment}
    fragment IProdxProductFragment on prodXProduct {
        responseProductId
        isSponsored
        product {
            ...IProductFragment
        }
    }
`;

const prodxSimplifiedProductFragment = gql`
    ${productImageFragment}
    fragment prodxSimplifiedProductFragment on prodXProduct {
        responseProductId
        product {
            productId
            name
            productImages(where: {isPrimary: true}) {
                ...ProductImageFragment
            }

            storeProduct(storeId: $storeId) {
                storeProductId
            }
        }
    }
`;

const predictionGroupFragment = gql`
    ${prodxProductFragment}

    fragment IPredictionGroupFragment on predictionGroup {
        date
        tags
        products {
            ...IProdxProductFragment
        }
    }
`;

export const getProductsByProductIds = gql`
    query getProductsByProductIds($productIds: [Int!], $pickupLocationHasLocker: Boolean!) {
        products(whereIds: $productIds) {
            products {
                ...IFullProductFragment
            }
        }
    }
    ${fullProductFragment}
`;

export const getProductsByProductIdsWithDetails = gql`
    ${productFragment}

    query getProductsByProductIdsWithDetails(
        $productIds: [Int!]
        $storeId: Int!
        $pickupLocationHasLocker: Boolean!
        $targeted: Boolean!
        $pageSize: Int!
        $page: Int = 1
        $retailItemEnabled: Boolean = false
        $locationIds: [ID!] = []
        $shouldMaintainWhereIdOrder: Boolean = false
        $wicEnabled: Boolean = false
        $foodHealthScoreEnabled: Boolean = false
    ) {
        products(
            whereIds: $productIds
            pageSize: $pageSize
            page: $page
            shouldMaintainWhereIdOrder: $shouldMaintainWhereIdOrder
        ) {
            products {
                ...IProductFragment
            }
            page
            nextPage
        }
    }
`;

export const getProductDetails = gql`
    ${productImageFragment}

    query getProductDetails($productId: Int!, $size: String!, $storeId: Int, $pickupLocationHasLocker: Boolean!) {
        product(productId: $productId) {
            productId
            size
            averageWeight
            name
            productImages(where: {isPrimary: true, size: $size}) {
                ...ProductImageFragment
            }
            productLockers @include(if: $pickupLocationHasLocker) {
                productLockerId
                pickupLocationId
                isLockerEligible
            }
            couponProductV4 {
                couponsV4 {
                    couponId
                    brand
                    offerState
                    valueText
                }
            }
        }
        storeProducts(where: {productId: $productId, storeId: $storeId, isActive: true}) {
            storeProducts {
                storeProductId
                onSale
                onFuelSaver
                fuelSaver
                isAlcohol
                price
                priceMultiple
                isWeighted
                product {
                    productId
                }
                department {
                    departmentId
                }
            }
        }
    }
`;

export const getItemByItemId = gql`
    query GetItemByItemId(
        $itemId: ID!
        $retailItemEnabled: Boolean = false
        $locationIds: [ID!] = []
        $wicEnabled: Boolean = false
    ) {
        itemById(itemId: $itemId) {
            ...IItemFragment
            retailItems(locationIds: $locationIds) @include(if: $retailItemEnabled) {
                ...IRetailItemFragment
                retailSection {
                    retailAisle {
                        sanitizedDescription
                    }
                }
            }
        }
    }

    ${itemFragment}
    ${retailItemsFragment}
`;

export const getListProduct = gql`
    ${productFragment}

    query GetListProduct(
        $productId: Int!
        $storeId: Int!
        $pickupLocationHasLocker: Boolean!
        $retailItemEnabled: Boolean = false
        $locationIds: [ID!] = []
        $targeted: Boolean = false
        $wicEnabled: Boolean = false
        $foodHealthScoreEnabled: Boolean = false
    ) {
        product(productId: $productId) {
            ...IProductFragment
        }
    }
`;

export const storeProductWithProductFragment = gql`
    ${productImageFragment}

    fragment storeProductWithProductFragment on storeProduct {
        storeProductId
        productId
        storeId
        onSale
        onFuelSaver
        isWeighted
        isAlcohol
        fuelSaver
        price
        priceMultiple
        basePrice
        basePriceMultiple
        isTagPriceLower
        department {
            departmentId
        }
        product {
            name
            brandName
            productId
            size
            averageWeight
            upc
            productImages(where: {isPrimary: true, size: "THUMBNAIL"}) {
                ...ProductImageFragment
            }
            productLockers @include(if: $pickupLocationHasLocker) {
                productLockerId
                pickupLocationId
                isLockerEligible
            }
            couponProductV4 {
                couponsV4 {
                    couponId
                    brand
                    offerState
                    valueText
                }
            }
        }
    }
`;

export const getStoreProductsDetails = gql`
    query getStoreProductsDetails(
        $storeId: Int!
        $productIds: [Int!]
        $page: Int!
        $pickupLocationHasLocker: Boolean = false
    ) {
        storeProducts(
            where: {storeId: $storeId, isActive: true}
            page: $page
            pageSize: 50
            whereProductIds: $productIds
        ) {
            page
            nextPage
            storeProducts {
                storeProductDescriptions {
                    description
                }
                ...storeProductWithProductFragment
            }
        }
    }
    ${storeProductWithProductFragment}
`;

export const getProductDetailsWithPrice = gql`
    query getProductDetailsWithPrice(
        $locationIds: [ID!] = []
        $retailItemEnabled: Boolean = false
        $productId: Int!
        $storeId: Int
        $pickupLocationHasLocker: Boolean!
        $targeted: Boolean = false
        $wicEnabled: Boolean = false
        $foodHealthScoreEnabled: Boolean = false
    ) {
        product(productId: $productId) {
            productId
            size
            productLockers @include(if: $pickupLocationHasLocker) {
                productLockerId
                pickupLocationId
                isLockerEligible
            }
            couponProductV4(targeted: $targeted) {
                couponsV4 {
                    couponId
                    offerState
                    valueText
                }
            }
            item {
                nutrition @include(if: $foodHealthScoreEnabled) {
                    score
                    activeBoosters {
                        code
                        title
                        value
                        description
                    }
                    activeDetractors {
                        code
                        title
                        value
                        description
                    }
                }
                ...IItemFragment
                retailItems(locationIds: $locationIds) @include(if: $retailItemEnabled) {
                    ...IRetailItemFragment
                }
            }
        }
        storeProducts(where: {productId: $productId, storeId: $storeId, isActive: true}) {
            storeProducts {
                storeProductId
                productId
                storeId
                onSale
                onFuelSaver
                isWeighted
                isAlcohol
                fuelSaver
                price
                priceMultiple
                basePrice
                basePriceMultiple
                isTagPriceLower
                department {
                    departmentId
                }
                storeProductDescriptions {
                    type
                    description
                }
                subcategoryId
                departmentGroup {
                    departmentGroupId
                    linkPath
                    name
                }
                department {
                    departmentId
                    linkPath
                    name
                }
                category {
                    categoryId
                    departmentId
                    linkPath
                    name
                    subcategories {
                        subcategoryId
                        linkPath
                        name
                    }
                }
                variations {
                    name
                    variationsAttributes {
                        name
                        variationsProducts {
                            productId
                            product {
                                productId
                                name
                            }
                        }
                    }
                }
            }
        }
    }
    ${itemFragment}
    ${retailItemsFragment}
`;

export const getProductVariations = gql`
    query getProductVariations($input: VariationsGroupsInput!) {
        variations(input: $input) {
            name
            variationsAttributes {
                name
                variationsProducts {
                    productId
                }
            }
        }
    }
`;

export const getProductNameByProductId = gql`
    query getProductNameByProductId($productId: Int!) {
        product(productId: $productId) {
            name
            productId
        }
    }
`;

export const getProductNamesByProductIds = gql`
    query getProductNamesByProductIds($productIds: [Int!]) {
        products(whereIds: $productIds) {
            products {
                productId
                name
            }
        }
    }
`;

export const getProductLockersEligibility = gql`
    query getProductLockersEligibility($productIds: [Int!]) {
        products(whereIds: $productIds) {
            products {
                productId
                productLockers {
                    productLockerId
                    pickupLocationId
                    isLockerEligible
                }
            }
        }
    }
`;

export const getSimilarStoreProducts = gql`
    query getSimilarStoreProducts($storeId: Int!, $productId: Int!, $pickupLocationHasLocker: Boolean = false) {
        similarStoreProducts(storeId: $storeId, productId: $productId) {
            storeProduct {
                ...storeProductWithProductFragment
            }
        }
    }
    ${storeProductWithProductFragment}
`;

export const getBuyAgainProducts = gql`
    ${productFragment}
    query GetBuyAgainProducts(
        $input: BuyAgainProductsInput
        $page: Int = 1
        $pageSize: Int
        $pickupLocationHasLocker: Boolean!
        $targeted: Boolean = false
        $storeId: Int!
        $retailItemEnabled: Boolean = false
        $locationIds: [ID!] = []
        $wicEnabled: Boolean = false
        $foodHealthScoreEnabled: Boolean = false
    ) {
        buyAgainProducts(input: $input, page: $page, pageSize: $pageSize) {
            responseId
            page
            nextPage
            totalCount
            nextPage
            page
            products {
                lastPurchaseDate
                lastPurchaseText
                product {
                    ...IProductFragment
                }
                purchaseFrequency
                responseProductId
                shelfProductId
                isSponsored
            }
            productGroups {
                name
                responseGroupId
                products {
                    lastPurchaseDate
                    lastPurchaseText
                    product {
                        ...IProductFragment
                    }
                    purchaseFrequency
                    responseProductId
                }
            }
        }
    }
`;

export const productCardQuery = gql`
    ${productFragment}

    query ProductCardQuery(
        $productId: Int!
        $storeId: Int!
        $pickupLocationHasLocker: Boolean!
        $targeted: Boolean = false
        $wicEnabled: Boolean = false
        $retailItemEnabled: Boolean = false
        $locationIds: [ID!]!
        $foodHealthScoreEnabled: Boolean = false
    ) {
        product(productId: $productId) {
            ...IProductFragment
        }
    }
`;

export const loadSearchProductsForProductCardsQuery = gql`
    query LoadSearchProductsForProductCardsQuery(
        $whereIds: [Int!]
        $storeId: Int!
        $pageSize: Int!
        $pickupLocationHasLocker: Boolean!
        $targeted: Boolean = false
        $retailItemEnabled: Boolean = false
        $locationIds: [ID!] = []
        $wicEnabled: Boolean = false
        $foodHealthScoreEnabled: Boolean = false
    ) {
        products(pageSize: $pageSize, whereIds: $whereIds) {
            products {
                ...IProductFragment
            }
        }
    }
    ${productFragment}
`;

export const getCustomerPredictionProductsQuery = gql`
    ${predictionGroupFragment}

    query GetCustomerPredictionProductsQuery(
        $customerUuid: String!
        $current: Boolean
        $storeId: Int!
        $groupLimit: Int
        $pickupLocationHasLocker: Boolean!
        $targeted: Boolean = false
        $retailItemEnabled: Boolean = false
        $locationIds: [ID!]!
        $wicEnabled: Boolean = false
        $foodHealthScoreEnabled: Boolean = false
    ) {
        predictionProducts(customerUuid: $customerUuid, current: $current, groupLimit: $groupLimit) {
            responseId
            groups {
                ...IPredictionGroupFragment
            }
        }
    }
`;

export const getCustomerRecommendationProductsListQuery = gql`
    ${prodxSimplifiedProductFragment}
    query GetCustomerRecommendationProductsListQuery(
        $storeId: Int!
        $customerUuid: String
        $aisleId: String!
        $productIds: [Int!]
    ) {
        recommendationsV2(storeId: $storeId, customerUuid: $customerUuid, aisleId: $aisleId, productIds: $productIds) {
            responseId
            recommendationsProducts {
                ...prodxSimplifiedProductFragment
            }
        }
    }
`;

export const getProductsComplements = gql`
    ${productFragment}
    query complementsProducts(
        $storeId: Int!
        $pickupLocationHasLocker: Boolean!
        $complementsProductsInput: ComplementsProductsInput!
        $targeted: Boolean = false
        $retailItemEnabled: Boolean = false
        $locationIds: [ID!] = []
        $wicEnabled: Boolean = false
        $foodHealthScoreEnabled: Boolean = false
    ) {
        complementsProducts(input: $complementsProductsInput) {
            responseProductId
            responseId
            productId
            slotTypeId
            shelfProductId
            product {
                ...IProductFragment
            }
        }
    }
`;

export const getProductAisleLocation = gql`
    query GetProductAisleLocation($itemId: ID!, $locationId: ID!) {
        itemById(itemId: $itemId) {
            itemId
            retailItems(locationIds: [$locationId]) {
                retailItemId
                retailSection {
                    id
                    description
                    sectionCode
                    retailAisle {
                        retailAisleId
                        aisleId
                        sanitizedDescription
                    }
                }
            }
        }
    }
`;

export const getAisleLocationsForProducts = gql`
    query GetAisleLocationForProducts($productIds: [Int!], $locationId: ID!) {
        products(whereIds: $productIds) {
            products {
                productId
                item {
                    itemId
                    retailItems(locationIds: [$locationId]) {
                        retailItemId
                        retailSection {
                            id
                            description
                            sectionCode
                            retailAisle {
                                retailAisleId
                                aisleId
                                sanitizedDescription
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const bannerCampaignQuery = gql`
    query bannerCampaignQuery(
        $productIds: [Int!]
        $storeId: Int!
        $pickupLocationHasLocker: Boolean!
        $targeted: Boolean = false
        $retailItemEnabled: Boolean = false
        $locationIds: [ID!] = []
        $wicEnabled: Boolean = false
        $foodHealthScoreEnabled: Boolean = false
    ) {
        products(whereIds: $productIds) {
            products {
                ...IProductFragment
            }
        }
    }
    ${productFragment}
`;

export const getRecommendedProducts = gql`
    ${prodxProductFragment}

    query GetRecommendationsV2(
        $storeId: Int!
        $productIds: [Int!]!
        $aisleId: String!
        $customerUuid: String
        $cartProductIds: [Int!]
        $locationIds: [ID!]!
        $targeted: Boolean = false
        $retailItemEnabled: Boolean = true
        $pickupLocationHasLocker: Boolean = false
        $wicEnabled: Boolean = false
        $foodHealthScoreEnabled: Boolean = false
    ) {
        recommendationsV2(
            storeId: $storeId
            productIds: $productIds
            aisleId: $aisleId
            customerUuid: $customerUuid
            cartProductIds: $cartProductIds
        ) {
            responseId
            recommendationsProducts {
                ...IProdxProductFragment
            }
        }
    }
`;
