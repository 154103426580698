import {BigNumber} from 'bignumber.js';

import {FulfillmentType, SellingRuleType} from '../../autogen/globalTypes';

export const isBulk = (product) => product.isWeighted && !product.averageWeight;

export const isEligibleForLocker = (pickupLocation, product) => {
    if (!pickupLocation || !product || !product.productLockers) {
        return false;
    }

    const pickupLocationId = pickupLocation.pickupLocationId;
    const relatedProductLockers = product.productLockers.find(
        (locker) => Number(locker.pickupLocationId) === Number(pickupLocationId)
    );

    if (!relatedProductLockers) {
        return false;
    }

    return relatedProductLockers.isLockerEligible;
};

export const getFormattedAverageWeight = ({isWeighted, averageWeight}, {quantity} = {}) => {
    if (!quantity || !isWeighted || !averageWeight) {
        return false;
    }

    const totalAverageWeight = new BigNumber(averageWeight).multipliedBy(quantity).toFixed(2);

    return `${totalAverageWeight} lbs`;
};

export const getSubtitle = (product) =>
    product.averageWeight && product.isWeighted ? getFormattedAverageWeight(product, {quantity: 1}) : product.size;

export const getWarningMessageForProduct = ({
    pickupLocationHasLocker,
    isStoreProductLockerEligible,
    isStoreProductAvailable,
    isStoreProductAlcoholic,
    fulfillmentCenterCanFulfillAlcohol,
    isStoreProductTobacco,
    isPickupAndSelfFulfilled,
    fulfillmentType,
    deliveryIneligible
}) => {
    let warningMessage = null;

    if (!isStoreProductAvailable) {
        warningMessage = 'Not available at this location';
    } else if (
        (isStoreProductAlcoholic && !fulfillmentCenterCanFulfillAlcohol) ||
        (fulfillmentType === FulfillmentType.DELIVERY && deliveryIneligible && !isStoreProductAlcoholic)
    ) {
        warningMessage = 'Store pickup only';
    } else if (isStoreProductTobacco && !isPickupAndSelfFulfilled) {
        warningMessage = 'Not available online';
    } else if (pickupLocationHasLocker && !isStoreProductLockerEligible) {
        warningMessage = 'Not eligible for locker pickup';
    }

    return warningMessage;
};

export const getWarningMessageForProductDetailsPage = ({
    pickupLocationHasLocker,
    isStoreProductLockerEligible,
    isStoreProductAvailable,
    isStoreProductAlcoholic,
    fulfillmentCenterCanFulfillAlcohol,
    isStoreProductTobacco,
    isPickupAndSelfFulfilled
}) => {
    if (!isStoreProductAvailable) {
        return 'Not available at this location';
    }

    if (isStoreProductAlcoholic && !fulfillmentCenterCanFulfillAlcohol) {
        return 'Store pickup only';
    }

    if (isStoreProductTobacco && !isPickupAndSelfFulfilled) {
        return 'Not available online';
    }

    if (pickupLocationHasLocker && !isStoreProductLockerEligible) {
        return 'Not available for locker pickup';
    }

    return null;
};

export const getMtoImageFilename = (mtoProductImages) => {
    if (mtoProductImages && mtoProductImages.length) {
        return `/webres/Image/catalog/${mtoProductImages[0].filename}`;
    }

    return null;
};

export const removeDuplicateProducts = (products) => {
    const productSet = new Set();
    const arr = [];

    if (products && products.length > 0) {
        for (const product of products) {
            if (product && !productSet.has(product.productId)) {
                productSet.add(product.productId);
                arr.push(product);
            }
        }
    }

    return arr;
};

export const getProductImage = (productImages, size) => {
    const image = productImages.find((productImage) => productImage.size === size);

    if (!image) {
        return undefined;
    }

    return image.uri;
};

export const getProductsWithStoreProducts = (productGroupProducts) => {
    const productsWithStoreProducts = productGroupProducts?.filter((productGroup) =>
        Boolean(productGroup?.product?.storeProduct)
    );

    if (!productsWithStoreProducts) {
        return [];
    }

    return productsWithStoreProducts;
};

export const isSnapEligible = (retailItem, featureFlag) =>
    featureFlag && retailItem?.sellingRules?.includes(SellingRuleType.SNAP_ELIGIBLE);
